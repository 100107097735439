.Eight {
    position: relative;
    width: 96%;
    height:  60vh;
    font-family: 'Gilroy-Regular';
    border-radius: 50px; /* Adding border-radius to the canvas */
    margin-bottom: 3%;
  }
  
  .Eight-canvas {
    width: 100%;
    height: 60vh;
    border-radius: 100px; /* Adding border-radius to the canvas */

    margin: 2%;
  }
  
  .html-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    pointer-events: none; /* To allow interactions with the canvas */
  }
  
  .left-section,
  .right-section {
    pointer-events: all; /* To enable interactions with the form */
    padding: 20px;
  }
  
  .left-section {
    flex: 1;
    pointer-events: none; /* To allow interactions with the canvas */
    font-family: 'Gilroy-Regular';

  }
  
  .svg-title {
    width: 100%;
    max-width: 500px;
    pointer-events: none; /* To allow interactions with the canvas */
    margin-left: 8%;
    margin-top: -15%;
  }
  
  .subtitle {
    font-size: 16px;
    margin-top: 20px;
    color: white;
    font-family: 'Gilroy-Regular';
    margin-left: 8%;
    font-size: 16px;
    font-weight: 500;
  }
  
  .right-section {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-left: 10%;
  }
  
  input,
  textarea {
    background: none;
    border: none;
    border-bottom: 1px solid white;
    color: white;
    margin-bottom: 20px;
    padding: 10px;
    font-size: 18px;
  }
  
  input::placeholder,
  textarea::placeholder {
    color: gray;
  }
  
  .checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    color: white;
    position: relative;
    
  }
  
  .checkbox-container input {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 10px;
    top: 10px;
    border-radius: 100px;
  }
  
  .submit-button {
    background: none;
    border: 1px solid white;
    padding: 15px;
    color: white;
    font-size: 18px;
    cursor: pointer;
    transition: background 0.3s;
    border-radius: 100px;
  }
  
  .submit-button:hover {
    background: white;
    color: black;
  }
  

    
  @media (max-width: 1366px) {
    .svg-title {
      width: 50%;
      max-width: 500px;
      pointer-events: none; /* To allow interactions with the canvas */
      margin-left: 8%;
      margin-top: -15%;
    }
    
  }