.FeatureWrapper {
    border-radius: 50px;
    background-color: #d2f65a;
    display: flex;
    flex-direction: column;
    padding: 47px 20px;
    width: auto;
    margin: 2%;
    overflow: hidden;

  }
  
  .ContentContainer {
    width: 1202px;
    max-width: 100%;
    padding: 2%;
  }
  
  .FeatureGrid {
    gap: 20px;
    display: flex;
  }
  
  @media (max-width: 991px) {
    .FeatureGrid {
      flex-direction: column;
      align-items: stretch;
      gap: 0;
    }
  }
  
  .FeatureColumn {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 45%;
    margin-left: 0;
  }
  
  @media (max-width: 991px) {
    .FeatureColumn {
      width: 100%;
    }
  }
  
  .FeatureInfo {
    display: flex;
    flex-direction: column;
    color: #000;
  }
  
  @media (max-width: 991px) {
    .FeatureInfo {
      margin-top: 40px;
    }
  }
  
  .FeatureTitle {
    letter-spacing: -0.4px;
    font: 700 20px/120% Gilroy-Regular, sans-serif;
  }
  
  .FeatureDescription {
    letter-spacing: -0.32px;
    margin-top: 33px;
    font: 500 16px/19px Gilroy-Regular, sans-serif;
  }
  
  .EcosystemInfo {
    position: static;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    font-size: 24px;
    color: #000;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: -0.72px;
    line-height: 100%;
    
  }
  
  @media (max-width: 991px) {
    .EcosystemInfo {
      margin-top: 40px;
    }
  }
  
  .Divider {
    border: none;
    height: 1px;
    background-color: #000;
    margin: 10px 0;
  }
  
  .Divider.blue {
    background-color: rgba(0, 18, 231, 0.2);
  }
  
  .EcosystemItem {
    font-family: Gilroy-Regular, sans-serif;
    margin: 10px 0 0 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: left;
  }
  
  .EcosystemItem.highlight {
    justify-content: left;
    align-items: left;
    margin-top: 10px;
    /* padding: 11px 10px; */
  }
  
  @media (max-width: 991px) {
    .EcosystemItem.highlight {
      padding-right: 20px;
    }
  }
  
  .EcosystemItem.active {
    background-color: #fff;
    padding: 5px 0px;
  }
  
  .Dot {
    display: inline-flex; /* изменено с 'flex' на 'inline-flex' */
    align-items: center; /* изменено с 'end' на 'center' */
    justify-content: center; /* изменено с 'end' на 'center' */
    width: 5px;
    height: 5px;
    background-color: black;
    border-radius: 50%;
    margin-left: 5px; /* уменьшено с 5% на 5px */
  }

  .FormatInfo {
    display: flex;
    margin-left: 15%;
    flex-grow: 1;
    flex-direction: column;
    color: #000;
  }
  
  @media (max-width: 991px) {
    .FormatInfo {
      margin-left: 0;
    }
  }
  
  .FormatDescription {
    position: relative;
    left: -10%;
    width: 30rem;
    letter-spacing: -0.32px;
    font: 500 16px/19px Gilroy-Regular, sans-serif;
  }
  
  @media (max-width: 991px) {
    .FormatDescription {
      width: 100%;
    }
  }
  
  .FormatTitle {
    letter-spacing: -4px;
    margin-top: 4%;
    font: 600 80px/100% Gilroy-Regular, sans-serif;
    white-space: nowrap;
    margin-bottom: 5%;
    margin-left: -10%;
  }
  
  @media (max-width: 991px) {
    .FormatTitle {
      font-size: 40px;
    }
  }
  
  .FormatSection {
    align-self: end;
    display: flex;
    margin-top: 0%;
    width: 1000px;
    max-width: 100%;
    flex-direction: column;
    color: #000;
    line-height: 120%;
  }
  
  .FormatList {
    display: flex;
    margin-top: 6px;
    gap: 20px;
  }
  
  @media (max-width: 991px) {
    .FormatList {
      max-width: 100%;
      flex-wrap: wrap;
      padding-right: 20px;
    }
  }
  
  .FormatCounter {
    letter-spacing: -0.32px;
    align-self: start;
    font: 700 16px Gilroy-Regular, sans-serif;
  }
  
  .FormatItems {
    display: flex;
    margin-left: 25%;
    flex-direction: column;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: -0.26px;
  }
  
  @media (max-width: 991px) {
    .FormatItems {
      margin-left: 0;
      max-width: 100%;
    }
  }
  
  .FormatItem {
    font-family: Gilroy-Regular, sans-serif;
    margin-top: 4px;
    cursor: pointer;
    width: 500px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  @media (max-width: 991px) {
    .FormatItem {
      max-width: 100%;
    }
  }
  
  .PlusIcon {
    color: gray;
    font-size: 20px;
    margin-left: auto;
  }
  
  .ItemDescription {
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 20px;
    width: 500px;
    font-size: 14px;
    color: #555;
  }
  
  @media (max-width: 991px) {
    .ItemDescription {
      width: 100%;
    }
  }
  
  .CallToAction {
    justify-content: center;
    align-items: center;
    border-radius: 65px;
    border: 1px solid #000;
    display: flex;
    margin-top: 60px;
    font-size: 20px;
    font-weight: 600;
    text-align: right;
    letter-spacing: -0.6px;
    padding: 38px 25px;
    background: none;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .CallToAction:hover {
    background-color: black;
    color: white;
  }
  
  @media (max-width: 991px) {
    .CallToAction {
      max-width: 100%;
      margin-top: 40px;
      padding: 38px 25px;
    }
  }
  
  .CTAText {
    font-family: Gilroy-Regular, sans-serif;
  }
  
  .CTAIcon {
    aspect-ratio: 1;
    object-fit: contain;
    width: 24px;
    margin-left: 2px;
  }
  

 /* 1366 */
 @media (max-width: 1366px) {

    .FormatTitle {
        display: flex;
        align-items: center;
        
        letter-spacing: -4px;
        margin-top: 14%;
        margin-left: -135%;
        font: 600 80px/100% Gilroy-Regular, sans-serif;
        margin-bottom: 30%;
      }
      
      .FormatSection {
        align-self: end;
        display: flex;
        margin-top: 0%;
        width: 1000px;
        max-width: 100%;
        flex-direction: column;
        color: #000;
        line-height: 120%;
      }


      .FormatList {
        display: flex;
        margin-top: 6px;
        gap: 20px;
        margin-left: -15%;
      }
      .FormatInfo {
        display: flex;
        margin-left: 15%;
        flex-grow: 1;
        flex-direction: column;
        color: #000;
      }
      .FormatDescription {
        width: 50rem;
        letter-spacing: -0.32px;
        font-family: 'Gilroy-regular', sans-serif;
        font-size: 1.5rem;
        position: relative;
        top: 65%;
        left: -160%;
        line-height: 2rem;
      }

      .FeatureColumn {
        display: flex;
        flex-direction: column;
        line-height: normal;
        width: 45%;
        margin-left: 0;
        
      }
      .FeatureGrid {
        width: 160%;
        gap: 20px;
        display: flex;
      }

      .CallToAction {
        justify-content: center;
        align-items: center;
        border-radius: 65px;
        border: 1px solid #000;
        display: flex;
        margin-top: 60px;
        font-size: 20px;
        font-weight: 600;
        text-align: right;
        letter-spacing: -0.6px;
        padding: 38px 25px;
        background: none;
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s;
        margin-left: -22%;
      }
  }

 /* 1200 */
 @media (max-width: 1200px) {

    .FormatTitle {
        display: flex;
        align-items: center;
        
        letter-spacing: -4px;
        margin-top: 14%;
        margin-left: -130%;
        font: 600 80px/100% Gilroy-Regular, sans-serif;
        margin-bottom: 30%;
      }
      
      .FormatSection {
        align-self: end;
        display: flex;
        margin-top: 0%;
        width: 1000px;
        max-width: 100%;
        flex-direction: column;
        color: #000;
        line-height: 120%;
      }


      .FormatList {
        display: flex;
        margin-top: 6px;
        gap: 20px;
        margin-left: -5%;
      }
      .FormatInfo {
        display: flex;
        margin-left: 15%;
        flex-grow: 1;
        flex-direction: column;
        color: #000;
      }
      .FormatDescription {
        width: 50rem;
        letter-spacing: -0.32px;
        font-family: 'Gilroy-regular', sans-serif;
        font-size: 1.5rem;
        position: relative;
        top: 65%;
        left: -150%;
        line-height: 2rem;
      }

      .FeatureColumn {
        display: flex;
        flex-direction: column;
        line-height: normal;
        width: 45%;
        margin-left: 0;
        
      }
      .FeatureGrid {
        width: 160%;
        gap: 20px;
        display: flex;
      }

      .CallToAction {
        justify-content: center;
        align-items: center;
        border-radius: 65px;
        border: 1px solid #000;
        display: flex;
        margin-top: 60px;
        font-size: 20px;
        font-weight: 600;
        text-align: right;
        letter-spacing: -0.6px;
        padding: 38px 25px;
        background: none;
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s;
        margin-left: -12%;
      }
  }



  /* 1024 */
  @media (max-width: 1024px) {

    .FormatTitle {
        display: flex;
        align-items: center;
        
        letter-spacing: -4px;
        margin-top: 14%;
        margin-left: -130%;
        font: 600 80px/100% Gilroy-Regular, sans-serif;
        margin-bottom: 30%;
      }
      
      .FormatSection {
        align-self: end;
        display: flex;
        margin-top: 0%;
        width: 1000px;
        max-width: 100%;
        flex-direction: column;
        color: #000;
        line-height: 120%;
      }


      .FormatList {
        display: flex;
        margin-top: 6px;
        gap: 20px;
        margin-left: -10%;
      }
      .FormatInfo {
        display: flex;
        margin-left: 15%;
        flex-grow: 1;
        flex-direction: column;
        color: #000;
      }
      .FormatDescription {
        width: 50rem;
        letter-spacing: -0.32px;
        font-family: 'Gilroy-regular', sans-serif;
        font-size: 1.5rem;
        position: relative;
        top: 65%;
        left: -160%;
        line-height: 2rem;
      }

      .FeatureColumn {
        display: flex;
        flex-direction: column;
        line-height: normal;
        width: 45%;
        margin-left: 0;
      }
      .FeatureGrid {
        width: 180%;
        gap: 20px;
        display: flex;
      }

      .CallToAction {
        justify-content: center;
        align-items: center;
        border-radius: 65px;
        border: 1px solid #000;
        display: flex;
        margin-top: 60px;
        font-size: 20px;
        font-weight: 600;
        text-align: right;
        letter-spacing: -0.6px;
        padding: 38px 25px;
        background: none;
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s;
        margin-left: 0%;
      }
  }


/* 1000 */
  @media (max-width: 1000px) {

    .FormatTitle {
        display: flex;
        align-items: center;
        
        letter-spacing: -4px;
        margin-top: 14%;
        margin-left: -130%;
        font: 600 80px/100% Gilroy-Regular, sans-serif;
        margin-bottom: 30%;
      }
      
      .FormatSection {
        align-self: end;
        display: flex;
        margin-top: 0%;
        width: 1000px;
        max-width: 100%;
        flex-direction: column;
        color: #000;
        line-height: 120%;
      }


      .FormatList {
        display: flex;
        margin-top: 6px;
        gap: 20px;
      }
      .FormatInfo {
        display: flex;
        margin-left: 15%;
        flex-grow: 1;
        flex-direction: column;
        color: #000;
      }
      .FormatDescription {
        width: 50rem;
        letter-spacing: -0.32px;
        font-family: 'Gilroy-regular', sans-serif;
        font-size: 1.5rem;
        position: relative;
        top: 65%;
        left: -160%;
        line-height: 2rem;
      }

      .FeatureColumn {
        display: flex;
        flex-direction: column;
        line-height: normal;
        width: 45%;
        margin-left: 0;
      }
      .FeatureGrid {
        width: 180%;
        gap: 20px;
        display: flex;
      }
  }