.five-screen-design {
  display: flex;
  padding: 80px 60px;
  font-family: Gilroy, bold;
  overflow: hidden;
}

.intro {
  width: 20%;
  margin-right: 40px;
}

.intro h2 {
  font-family: 'Gilroy-Bold', Arial, sans-serif; /* Apply Gilroy Bold font */

  font-size: 20px;
  margin-bottom: 20px;
  font-weight: 700;
  line-height: 24px;
}

.intro p {
  font-family: 'Gilroy-Regular', Arial, sans-serif; /* Apply Gilroy Bold font */
  font-size: 16px;
  color: black;
  font-weight: 500;

}

.reviews {
  width: 80%;
  display: flex;
  gap: 40px;
  margin-top: 2%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.review {
  background-color: #f9f9f9;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.company {
  background-color: #d3e234;
  padding: 10px 20px;
  border-radius: 40px;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Gilroy-Regular', Arial, sans-serif; /* Apply Gilroy Bold font */
  line-height: 19.41px;
  width: 221px;
  height: 49px;
}

.review-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.review-text {
  font-family: 'Gilroy-regular', Arial, sans-serif; /* Apply Gilroy Bold font */
  user-select: none;
  font-weight: 500;
width: 773px;
height: 356px;
  font-size: 24px;
  line-height:27.6px;
}

.review-footer {
  display: flex;
  align-items: center;
  margin-top: 20px;
}


.reviewer {
  display: flex;
  align-items: center;
}

.reviewer-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.reviewer-info {
  font-size: 14px;
  font-family: Gilroy-regular, sans-serif;

}

.reviewer-name {
  font-weight: bold;
  font-family: Gilroy-regular, sans-serif;
  font-size: 20px;
}

.reviewer-position {
  margin-top: 3%;
  color: black;
}

