.company-overview {
  background-color: #fff;
  padding: 60px 0 10px;
  overflow: hidden;

}

.content-wrapper {
  max-width: 1881px;
  margin: 0 auto;
  padding: 0 20px;
}

.company-header {
  display: flex;
  gap: 20px;
  color: #000;
  margin-bottom: 18px;
}

.company-title {
  font-family: 'Gilroy-bold';
  font-size: 20px;
  letter-spacing: -0.4px;
}

.company-slogan {
  font-family: 'Gilroy-semibold';
  font-size: 80px;
  letter-spacing: -4px;
  margin-left: 10%;
  margin-top: 1%;
  line-height: 100%;
}

.stats-container {
  display: flex;
  gap: 20px;
  margin-left: 18.5%;
  margin-top: -6%;
  margin-bottom: 30px;
}

.stat-card {
  margin-top:3%;
  flex: 1;
  border-radius: 835.112px;
  padding: 8px 75px;
  background-color: #f7f7f7;
  height:205px;
  width:559px;
  align-items:center;
  justify-content:center;
  font-family: 'Gilroy-Regular';
}

.stat-content {
  display: flex;
  gap: 20px;
}

.stat-number {
  color: #000;
  font-family: 'Gilroy-medium';
  letter-spacing: -10.8px;
  font-size:180px;
}

.stat-description {
  color: #000;
  font: 500 25px/26px Gilroy-Regular, -apple-system, Roboto, Helvetica, sans-serif;
  letter-spacing: -0.5px;
  align-self: center;
}

.company-details {
  display: flex;
  gap: 20px;
}

.company-description {
  flex: 0 0 16%;
  font-size: 16px;
  color: #000;
  transition: transform 0.5s ease;
}

.company-description p {
  font: 500 16px/19px Gilroy-Regular, sans-serif;
  letter-spacing: -0.32px;
  margin-bottom: 25px;
}

.learn-more {
  display: flex;
  align-items:center;
  gap: 2px;
  font-family: 'Gilroy-bold';
  font-size: 16px;
  letter-spacing: -0.4px;
}

.arrow-icon {
  width: 17px;
  height: auto;
}

.company-image {
  flex: 0 0 84%;
}

.overview-image {
  width: 100%;
  border-radius: 110px;
  aspect-ratio: 1.79;
  object-fit: cover;
  transform: scale(0.2);
  transform-origin: top left;
  transition: transform 0.5s ease;
}

/* 1600 */
@media (max-width: 1600px) {
  .company-slogan {
    font-size: 40px;
    letter-spacing: -3px;
  }

  .stat-number {
    font-size: 100px;
    letter-spacing: -8.4px;
  }

  .stat-card {
    margin-top: 5%;
    height: 8rem;
    width: 450px;
  }
}

/* 1536 */
@media (max-width: 1536px) {
  .company-slogan {
    font-size: 40px;
    letter-spacing: -3px;
  }

  .stat-number {
    font-size: 100px;
    letter-spacing: -8.4px;
  }

  .stat-card {
    margin-top: 5%;
    height: 8rem;
    width: 450px;
  }
}

/* 1440 */
@media (max-width: 1440px) {
  .company-slogan {
    font-size: 40px;
    letter-spacing: -3px;
  }

  .stat-number {
    font-size: 80px;
    letter-spacing: -8.4px;
  }

  .stat-card {
    display: flex;
    margin-top: 5%;
    height: 6rem;
    width: 10rem;
  }
}

/* 1366 */
@media (max-width: 1366px) {
  .company-slogan {
    font-size: 40px;
    letter-spacing: -3px;
  }

  .stat-number {
    font-size: 80px;
    letter-spacing: -8.4px;
  }

  .stat-card {
    display: flex;
    margin-top: 5%;
    height: 6rem;
    width: 10rem;
  }
}

/* 1200 */
@media (max-width: 1200px) {
  .company-slogan {
    font-size: 35px;
    letter-spacing: -3px;
  }

  .stat-number {
    font-size: 60px;
    letter-spacing: -3.4px;
  }

  .stat-card {
    display: flex;
    margin-top: 5%;
    height: 6rem;
    width: 8rem;
  }
}

/* 1024 */
@media (max-width: 1024px) {
  .company-slogan {
    font-size: 35px;
    letter-spacing: -1px;
  }

  .stat-number {
    display: flex;
    align-items: center;
    font-size: 50px;
    letter-spacing: -3.4px;
  }

  .stat-card {
    display: flex;
    align-items: center;

    margin-top: 5%;
    height: 6rem;
    width: 6rem;
  }


}

/* 800 */
@media (max-width: 800px) {
  .company-slogan {
    font-size: 25px;
    letter-spacing: -1px;
  }

  .stat-number {
    display: flex;
    align-items: center;
    font-size: 30px;
    letter-spacing: -3.4px;
  }

  .stat-card {
    display: flex;
    align-items: center;
    margin-right: -3%;
    margin-top: 5%;
    height: 3rem;
    width: 2rem;
  }

  .stat-description {
    color: #000;
    font-family: 'Gilroy-regular', sans-serif;
    font-size:1rem;
    letter-spacing: -0.5px;
    line-height: 1rem;
    align-self: center;
  }
  .company-description {
    flex: 0 0 16%;
    font-size: 12px;
    color: #000;
    transition: transform 0.5s ease;
  }
  .company-description p {
    flex: 0 0 16%;
    font-size: 12px;
    color: #000;
    transition: transform 0.5s ease;
    line-height: 1rem;
  }
}

/* 350 */
@media (max-width: 350px) {
  .company-slogan {
    font-size: 25px;
    letter-spacing: -1px;
  }

  .stat-number {
    display: flex;
    align-items: center;
    font-size: 30px;
    letter-spacing: -3.4px;
  }

  .stat-card {
    display: flex;
    align-items: center;
    margin-right: -3%;
    margin-top: 5%;
    height: 3rem;
    width: 2rem;
  }

  .stat-description {
    color: #000;
    font-family: 'Gilroy-regular', sans-serif;
    font-size:1rem;
    letter-spacing: -0.5px;
    line-height: 1rem;
    align-self: center;
  }
  .company-description {
    flex: 0 0 16%;
    font-size: 12px;
    color: #000;
    transition: transform 0.5s ease;
  }
  .company-description p {
    flex: 0 0 16%;
    font-size: 12px;
    color: #000;
    transition: transform 0.5s ease;
    line-height: 1rem;
  }
}