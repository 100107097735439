@font-face {
  font-family: 'Gilroy-Black';
  src: url('../src/fonts/GILROY-BLACK.TTF') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-BlackItalic';
  src: url('../src/fonts/GILROY-BLACKITALIC.TTF') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy-Bold';
  src: url('../src/fonts/GILROY-BOLD.TTF') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-BoldItalic';
  src: url('../src/fonts/GILROY-BOLDITALIC.TTF') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy-Extrabold';
  src: url('../src/fonts/GILROY-EXTRABOLD.TTF') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-ExtraboldItalic';
  src: url('../src/fonts/GILROY-EXTRABOLDITALIC.TTF') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy-Heavy';
  src: url('../src/fonts/GILROY-HEAVY.TTF') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-HeavyItalic';
  src: url('../src/fonts/GILROY-HEAVYITALIC.TTF') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy-Light';
  src: url('../src/fonts/GILROY-LIGHT.TTF') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-LightItalic';
  src: url('../src/fonts/GILROY-LIGHTITALIC.TTF') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy-Medium';
  src: url('../src/fonts/GILROY-MEDIUM.TTF') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-MediumItalic';
  src: url('../src/fonts/GILROY-MEDIUMITALIC.TTF') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy-Regular';
  src: url('../src/fonts/GILROY-REGULAR.TTF') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-Regular';
  src: url('../src/fonts/Gilroy-Regular.woff') format('woff2'),
       url('../src/fonts/Gilroy-Regular.woff2') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-Semibold';
  src: url('../src/fonts/GILROY-SEMIBOLD.TTF') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-SemiboldItalic';
  src: url('../src/fonts/GILROY-SEMIBOLDITALIC.TTF') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy-Thin';
  src: url('../src/fonts/GILROY-THIN.TTF') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-ThinItalic';
  src: url('../src/fonts/GILROY-THINITALIC.TTF') format('truetype');
  font-weight: 100;
  font-style: italic;
}


@font-face {
  font-family: 'Gilroy-UltraLightItalic';
  src: url('../src/fonts/GILROY-ULTRALIGHTITALIC.TTF') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'VCROSDMono';
  src: url('../src/fonts/VCR OSD MONO CYR.TTF') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Gilroy', Gilroy-Regular;
 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* cursor: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMwLjUgMTZDMzAuNSAyNC4wMDgxIDI0LjAwODEgMzAuNSAxNiAzMC41QzcuOTkxODcgMzAuNSAxLjUgMjQuMDA4MSAxLjUgMTZDMS41IDcuOTkxODcgNy45OTE4NyAxLjUgMTYgMS41QzI0LjAwODEgMS41IDMwLjUgNy45OTE4NyAzMC41IDE2WiIgc3Ryb2tlPSJibGFjayIvPgo8cGF0aCBkPSJNNiA5QzcuNjU2ODUgOSA5IDcuNjU2ODUgOSA2QzkgNC4zNDMxNSA3LjY1Njg1IDMgNiAzQzQuMzQzMTUgMyAzIDQuMzQzMTUgMyA2QzMgNy42NTY4NSA0LjM0MzE1IDkgNiA5WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg=="),
    auto; */
  
}
body canvas{
  margin: 0;
  font-family: 'Gilroy', Gilroy-Regular;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* cursor: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMwLjUgMTZDMzAuNSAyNC4wMDgxIDI0LjAwODEgMzAuNSAxNiAzMC41QzcuOTkxODcgMzAuNSAxLjUgMjQuMDA4MSAxLjUgMTZDMS41IDcuOTkxODcgNy45OTE4NyAxLjUgMTYgMS41QzI0LjAwODEgMS41IDMwLjUgNy45OTE4NyAzMC41IDE2WiIgc3Ryb2tlPSJibGFjayIvPgo8cGF0aCBkPSJNNiA5QzcuNjU2ODUgOSA5IDcuNjU2ODUgOSA2QzkgNC4zNDMxNSA3LjY1Njg1IDMgNiAzQzQuMzQzMTUgMyAzIDQuMzQzMTUgMyA2QzMgNy42NTY4NSA0LjM0MzE1IDkgNiA5WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg=="),
    auto; */
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.OneScreen{
  position: relative;
    width: 100%;
    height: 100%;
}
.OneScreen canvas{
  
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  

}




.TwoScreen{
  position: relative;
 
    width: 100%;
    height: 100%;
}
.ThreeScreen{
  position: relative;
 
    width: 100%;
    height: 100%;
}
.ThreeScreen canvas{
  
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  

}

.EightScreen{
  position: relative;
    width: 100%;
    height: 100%;

}
.EightScreen canvas{
  
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  

}

/* .NineScreen{
  position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.NineScreen canvas{
  
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  

} */


