.six-screen-design {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.title {
  margin-top: 4%;
  margin-left: -60%;
}

.logos {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 20px;
}

.logo-card {
  width: 245px;
  height: 245px;
  background-color: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 1s cubic-bezier(0.25, 0.8, 0.5, 1);
  border: 1px solid #e0e0e0;
  clip-path: inset(0 0 0 0);
  border-radius: 8px;
}

.logo-card img {
  max-width: 80%;
  max-height: 80%;
}


/* 1600 */
@media (max-width: 1600px) {
  .six-screen-design {
    display: flex;
    align-items: center;
    width: 90%;
    transform: scale(0.9);
  }
  

  }

  /* 1536 */
@media (max-width: 1536px) {
  .six-screen-design {
    display: flex;
    align-items: center;
    width: 90%;
    transform: scale(0.85);
  }
  

  }

  
  /* 1366 */
@media (max-width: 1366px) {
  .six-screen-design {
    display: flex;
    align-items: center;
    width: 90%;
    transform: scale(0.75);
  }
  

  }

    /* 1200 */
@media (max-width: 1200px) {
  .six-screen-design {
    margin-top: -15%;
    margin-bottom: -15%;
    display: flex;
    align-items: center;
    width: 90%;
    transform: scale(0.65);
  }
  

  }

      /* 1024 */
@media (max-width: 1024px) {
  .six-screen-design {
    margin-top: -25%;
    margin-bottom: -25%;
    display: flex;
    align-items: center;
    width: 90%;
    transform: scale(0.55);
  }
  

  }