
.Sevencontainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    
    justify-content: center;
    font-family: 'Gilroy', sans-serif;
    font-size: 2rem;
    color: #000;
    overflow: hidden;
    transition: all 12s cubic-bezier();
    
}



.circle {
    width: 20px;
    height: 20px;
    border-radius: 60px;
    display: flex;
    margin-right: 10px;
    transition: all 12s cubic-bezier();

}

.blue {
    background-color: blue;
}

.green {
    background-color: #D0FF00;
    
}

.orange {
    background-color: orange;
}

.green-light {
    background-color: #C7F464;
}

.pink {
    background-color: #F34A87;
}

.text {
    display: flex;

    margin-right: 20px;
    font-size: 128px;
    font-weight: 500;
    line-height: 155.26px;
    
}


.row1 {
    
    display: flex;
    align-items: center;
    margin-bottom: -10px;
    max-width: 100%;
    justify-content: center;
    transition: all 12s cubic-bezier();

}
.row2 {
    display: flex;
    align-items: center;
    margin-bottom: -10px;
}
.row3 {
    display: flex;
    align-items: center;
    margin-bottom: -10px;
    max-width: 100%;
    justify-content: right;
}
.row4 {
    display: flex;
    align-items: center;
    margin-bottom: -10px;
    
}
.row5 {
    display: flex;
    align-items: center;
    margin-bottom: -10px;
    max-width: 100%;
    justify-content: right;

}
.expand-right {
    transform-origin: right center;
}

.expand-left {
    transform-origin: left center;
}


/* 1600 */
@media (max-width: 1600px) {
    .Sevencontainer {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        
        justify-content: center;
        font-family: 'Gilroy', sans-serif;
        font-size: 1.5rem;
        color: #000;
        overflow: hidden;
        transition: all 12s cubic-bezier();
        
    }
    
    
.text {
    display: flex;

    margin-right: 20px;
    font-size: 118px;
    font-weight: 500;
    line-height: 155.26px;
    
}

    
  
    }



    /* 1440 */
    @media (max-width: 1440px) {
        .Sevencontainer {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            
            justify-content: center;
            font-family: 'Gilroy', sans-serif;
            font-size: 1.5rem;
            color: #000;
            overflow: hidden;
            transition: all 12s cubic-bezier();
            
        }
        
        
    .text {
        display: flex;
    
        margin-right: 20px;
        font-size: 78px;
        font-weight: 500;
        line-height: 155.26px;
        
    }
    
        
    .circle {
        width: 10px;
        height: 10px;
        border-radius: 60px;
        display: flex;
        margin-right: 10px;
        transition: all 12s cubic-bezier();
    
    }
        }
    



    /* 1366 */
@media (max-width: 1366px) {
    .Sevencontainer {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        
        justify-content: center;
        font-family: 'Gilroy', sans-serif;
        font-size: 1.5rem;
        color: #000;
        overflow: hidden;
        transition: all 12s cubic-bezier();
        
    }
    
    
.text {
    display: flex;

    margin-right: 20px;
    font-size: 78px;
    font-weight: 500;
    line-height: 155.26px;
    
}

    
.circle {
    width: 10px;
    height: 10px;
    border-radius: 60px;
    display: flex;
    margin-right: 10px;
    transition: all 12s cubic-bezier();

}
    }


/* 1200 */
@media (max-width: 1200px) {
    .Sevencontainer {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        
        justify-content: center;
        font-family: 'Gilroy', sans-serif;
        font-size: 1.5rem;
        color: #000;
        overflow: hidden;
        transition: all 12s cubic-bezier();
        
    }
    
    
.text {
    display: flex;

    margin-right: 20px;
    font-size: 68px;
    font-weight: 500;
    line-height: 155.26px;
    
}

    
.circle {
    width: 10px;
    height: 10px;
    border-radius: 60px;
    display: flex;
    margin-right: 10px;
    transition: all 12s cubic-bezier();

}
    }




/* 1024 */
@media (max-width: 1024px) {
    .Sevencontainer {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        
        justify-content: center;
        font-family: 'Gilroy', sans-serif;
        font-size: 1.5rem;
        color: #000;
        overflow: hidden;
        transition: all 12s cubic-bezier();
        
    }
    
    
.text {
    display: flex;

    margin-right: 20px;
    font-size: 48px;
    font-weight: 500;
    line-height: 155.26px;
    
}

    
.circle {
    width: 10px;
    height: 10px;
    border-radius: 60px;
    display: flex;
    margin-right: 10px;
    transition: all 12s cubic-bezier();

}
    }