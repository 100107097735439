/* BallsDesign.css */

.balls-design {
  position: absolute;
  top: 0;
  left: 0;
  width: 95%;
  height: 100%;
  padding: 2%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

}



.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.logo {
  position: relative;
  font-family: 'Gilroy', regular;
  flex: 1 1 0%;
  height: 30px;
  font-size: 30px;
  font-weight: 700;
  line-height: 30px;
  color: black;
  letter-spacing: -2px;
  margin-top: 0%;
  z-index: 9999;
  cursor: pointer;
}

.present {
  position: relative;
  top: 3%;
  left: 1%;
  display: flex;
  font-size: 24px;
  
  line-height: 24px;
 
  height: 2rem;
  width:14rem;
  z-index: 9999;
  cursor: pointer;
}

.spacer-60 {
  height: 60px;
}

.spacer-10 {
  height: 10px;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.text-small {
  flex: 1 1 0%;
  font-size: 12px;
  line-height: 1.5em;
  color: black;
}

.main-title {
  font-family: 'Gilroy', sans-serif;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-top: -1.5%;
}

.main-text {
  font-size: 166px;
  line-height: 148.8px;
  color: black;
  margin-top: -2.5%;
  letter-spacing: -10px;
  font-weight: 600;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  position: relative;
  top: 90%;
}

.footer {
  pointer-events: all;
  pointer: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
}

.right-align {
  flex: 1 1 0%;
  font-size: 12px;
  line-height: 1em;
  text-align: right;
  color: black;
}

/* 1600 */
@media (max-width: 1600px) {

  canvas{
    width: 100%;
    height: 100%;
  }

  .main-text {
    position: relative;
    width: 100%;
    top: 66%;

  }

  .logo {
    position: relative;
    top: -60%;
    left: 0%;
    display: flex;
    font-size: 24px;
    height: 24px;
    line-height: 24px;
    
  }

  .present {
    position: relative;
    top: -80%;
    left: -1%;
    display: flex;
    font-size: 24px;
    
    line-height: 24px;
   
    height: 2rem;
    width:14rem;
  }

  canvas{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
}


/* 1536 */
@media (max-width: 1536px) {
  .main-text {
    position: relative;
    width: 100%;
    top: 63%;

  }
  canvas{
    width: 100%;
    height: 100%;
  }

  .logo {
    position: relative;
    top: -60%;
    left: 0%;
    display: flex;
    font-size: 24px;
    height: 24px;
    line-height: 24px;
    
  }

  .presa {
    position: relative;
    top: -70%;
    left: 37%;
    display: flex;
    font-size: 24px;
    height: 24px;
    line-height: 24px;
    height: 17px;
    width: 180px;
  }

  canvas{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
}

/* 1366 */
@media (max-width: 1366px) {
  .main-text {
    position: relative;
    width: 100%;
    top: 50%;

  }


  .logo {
    position: relative;
    top: -70%;
    left: -2%;
    display: flex;
    font-size: 24px;
    height: 24px;
    line-height: 24px;
    transform: scale(0.9);
  }

  .present {
    position: relative;
    top: -80%;
    left: -1%;
    display: flex;
    font-size: 24px;
    
    line-height: 24px;
    transform: scale(0.9);

    height: 2rem;
    width:14rem;
  }

  canvas{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
}

/* 1200 */
@media (max-width: 1200px) {
  .main-text {
    position: relative;
    width: 100%;
    top: 45%;

  }

  .logo {
    position: relative;
    top: -70%;
    left: -4%;
    display: flex;
    font-size: 24px;
    height: 24px;
    line-height: 24px;
    
  }

  .present {
    position: relative;
    top: -80%;
    left: -1%;
    display: flex;
    font-size: 24px;
    
    line-height: 24px;
    transform: scale(0.8);

    height: 2rem;
    width:14rem;
  }


  canvas{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
}
/* 1024 */
@media (max-width: 1024px) {
  .main-text {
    position: relative;
    width: 100%;
    top: 36%;

  }

  .logo {
    position: relative;
    top: -60%;
    left: -7%;
    display: flex;
    font-size: 24px;
    height: 24px;
    line-height: 24px;
    transform: scale(0.8);
  }

  .present {
    position: relative;
    top: -60%;
    left: 4%;
    display: flex;
    font-size: 24px;
    
    line-height: 24px;
    transform: scale(0.7);

    height: 2rem;
    width:14rem;
  }


  canvas{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
}
/* планшеты */
@media (max-width: 800px) {
  .main-text {
    position: relative;
    width: 100%;
    top: 8%;

  }

  .logo {
    position: relative;
    top: -180%;
    left: -35%;
    display: flex;
    font-size: 24px;
    height: 24px;
    line-height: 24px;
    transform: scale(0.8);
  }

  .present {
    position: relative;
    top: 3%;
    left: -10%;
    display: flex;
    font-size: 24px;
    
    line-height: 24px;
    transform: scale(0.8);
    height: 100%;
    width: 10%;
  }

  canvas{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
}
/* планшеты */
@media (max-width: 768px) {
  .main-text {
    position: relative;
    width: 100%;
    top: 6%;

  }

  .logo {
    position: relative;
    top: -180%;
    left: -35%;
    display: flex;
    font-size: 24px;
    height: 24px;
    line-height: 24px;
    
  }

  .presa {
    position: relative;
    top: -165%;
    left: 60%;
    display: flex;
    font-size: 24px;
    height: 24px;
    line-height: 24px;
    height: 17px;
    width: 180px;
  }

  canvas{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
}
/* Мобильная часть */
@media (max-width: 350px) {
  .main-text {
    position: relative;
    width: 100%;
    top: -23%;

  }

  .logo {
    position: relative;
    top: -260%;
    left: -75%;
    display: flex;
    font-size: 24px;
    height: 24px;
    line-height: 24px;
    
  }

  .presa {
    position: relative;
    top: -250%;
    left: 85%;
    display: flex;
    font-size: 24px;
    height: 24px;
    line-height: 24px;
    height: 17px;
    width: 180px;
  }

  canvas{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
}
