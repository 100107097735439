/* Header.css */

.Globalheader {
    position: fixed;
    top: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 999;
    font-family: 'Gilroy-regular';
    background-color: transparent; /* No background color */
}

.menu {
    display: flex;
    align-items: center;
}

.menu-items {
    display: flex;
    align-items: center;
    background-color: #D2F65A; /* Light green background */
    padding: 15px 50px;
    border-radius: 50px;
    line-height: 16.8px;
    font-size: 14px;
}

.menu-item {
    color: #000; /* Black color for text */
    text-decoration: none;
    margin: 0 20px;
    position: relative;
    transition: color 0.3s;
    font-weight: 600;
    font-size: 14;
    z-index: 9999;

}

.menu-item:hover {
    color: #000;
    z-index: 9999;
    
   
}

.menu-item:hover::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: #000; /* Black underline */
    left: 0;
    bottom: -5px;
    z-index: 9999;

}

.menu-icons {
    display: flex;
    align-items: center;
    
}

.icon {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: #ccc; /* Light gray color */
    display: flex;
    align-items: center;
    justify-content: center;
   
}

.icon img {
    width: 24px;
    height: 24px;
}


@media (max-width: 1600px) {
    .Globalheader {
        position: relative;
        top: 30px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9999;
        transform: scale(1);
        font-family: 'Gilroy-regular';
        background-color: transparent; /* No background color */
    }
  }

@media (max-width: 1200px) {
    .Globalheader {
        position: relative;
        top: 30px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9999;
        transform: scale(0.8);
        font-family: 'Gilroy-regular';
        background-color: transparent; /* No background color */
    }
  }
  
  @media (max-width: 800px) {
    .Globalheader {
        position: relative;
        top: 30px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9999;
        transform: scale(0.6);

        font-family: 'Gilroy-regular';
        background-color: transparent; /* No background color */
    }
  }

  @media (max-width: 768px) {
    .Globalheader {
        position: relative;
        top: 30px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9999;
        transform: scale(0.6);

        font-family: 'Gilroy-regular';
        background-color: transparent; /* No background color */
    }
  }
  
  @media (max-width: 480px) {
    .Globalheader {
        position: relative;
        top: 30px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9999;
        transform: scale(0.4);

        font-family: 'Gilroy-regular';
        background-color: transparent; /* No background color */
    }
    .menu-items {
        width: 100%;
        white-space: nowrap;

        display: flex;
        align-items: center;
        background-color: #D2F65A; /* Light green background */
        padding: 20px 50px;
        justify-content: center;
        border-radius: 50px;
        line-height: 16.8px;
        font-size: 14px;
        
    }
    .menu-item {
        white-space: nowrap;

        width: 100%;
        color: #000; /* Black color for text */
        text-decoration: none;
        margin: 0 20px;
        position: relative;
        transition: color 0.3s;
        font-weight: 600;
        font-size: 14;
    }
  }
  